<template>
  <CFooter :fixed="false" class="bg-white shadow-sm">
    <div class="container-fluid text-center text-dark">
      CHED Caraga Management Information System (CCMIS)
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
