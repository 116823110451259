export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            badge: {

            }
        }, {

            _name: 'CSidebarNavItem',
            name: 'Institutions',
            to: '/heis',
            icon: 'cil-institution',
            badge: {}

        },
        {

            _name: 'CSidebarNavItem',
            name: 'Programs',
            to: '/programs',
            icon: 'cil-school',
            badge: {
            }

        },
        {

            _name: 'CSidebarNavItem',
            name: 'Program Applications',
            to: '/program-apps',
            icon: 'cil-file',
            badge: {
            }

        },
        {

            _name: 'CSidebarNavDropdown',
            name: 'Records',
            icon: 'cil-folder',
            badge: {},
            items: [{
                    name: 'Students',
                    to: '/students',
                    icon: 'cil-people',
                },
                {
                    name: 'Special Orders',
                    to: '/special-orders',
                    icon: 'cil-file',
                },
                {
                    name: 'CAV',
                    icon: 'cil-shield-alt',
                    to: '/cavs'
                }
            ]


        },
        {

            _name: 'CSidebarNavDropdown',
            name: 'Human Resources',
            route: '/hr',
            icon: 'cil-people',
            items: [{
                    name: 'Daily Time Records',
                    to: '/hr/dtr',
                    icon: 'cil-clock'
                },
                {
                    name: 'Online Biometric',
                    to: '/hr/biometric',
                    icon: 'cil-calendar'
                },
            ]

        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'General Services',
            route: '/general-services',
            icon: 'cil-speedometer',
            items: [{
                name: 'Inventory System',
                to: '/general-services/inventory'
            }, ]

        },
        {

            _name: 'CSidebarNavItem',
            name: 'CAV Application',
            to: '/cav-application',
            icon: 'cil-file',
            badge: {
            }

        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Budget',
            route: '/budget',
            icon: 'cil-dollar',
            items: [
                {
                    name: 'Chart',
                    to: '/budget/chart',
                    icon: 'cil-file'
                },
                {
                    name: 'Program',
                    to: '/budget/program',
                    icon: 'cil-file'
                },
                {
                    name: 'List of RAOD',
                    to: '/budget/list-raod',
                    icon: 'cil-file'
                }, 
                {
                    name: 'List of Modified RAOD',
                    to: '/budget/list-modified-raod',
                    icon: 'cil-file'
                },
                {
                    name: 'List of Disbursed',
                    to: '/budget/list-disbursed',
                    icon: 'cil-file'
                },
            ]

        },

    ]
}]